<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" >
    <a-form-model-item label="渠道编码" prop="code">
      <a-input v-model="form.code" />
    </a-form-model-item>
    <a-form-model-item label="渠道费率" prop="feeRate">
      <a-input v-model="form.feeRate" />
    </a-form-model-item>
    <a-form-model-item label="商户编号" prop="merchantId">
      <a-input v-model="form.merchantId" />
    </a-form-model-item>
    <a-form-model-item label="开启状态" prop="statusChecked" @change="onChange($event, record)">
      <a-switch v-model="form.statusChecked" checked-children="开" un-checked-children="关"/>
    </a-form-model-item>
    <a-form-model-item label="配置json" prop="config">
      <a-input v-model="form.config" type="textarea" />
    </a-form-model-item>
    <a-form-model-item label="备注" prop="remark">
      <a-input v-model="form.remark" type="textarea" />
    </a-form-model-item>
    <div class='foot-btn'>
      <a-button :style="{ marginRight: '8px' }" @click='onClose'>
        取消
      </a-button>
      <a-button type='primary' @click="handleSubmit">
        确定
      </a-button>
    </div>
  </a-form-model>
</template>
<script>
import * as paychannel from '@/api/pay/pay.js'

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {

      form: {
        code: '',
        feeRate: '',
        config: '', // 渠道配置的 json 字符串
        merchantId: '26',
        status: 0,
        statusChecked: false,
        remark: ''
      },
      rules: {
        code: [
          {required: true, message: '请输入渠道编码'},
        ],
        feeRate: [
          {required: true, message: '请输入渠道费率'},
        ],
        merchantId: [
          {required: true, message: '请选择商户id'},
        ],
      }
    }
  },
  watch: {
    id(val) {
      console.log('监听id是否有值', val)
    }
  },
  methods: {
    /**
     * 根据id获取详情
     */
    getInfo() {
      paychannel.detail({id: this.id}).then(res => {
        if (res.success) {
          let obj = res.data
          obj.statusChecked = false
          obj.status === 1 ? obj.statusChecked = true :
            obj.status === 0 ? obj.statusChecked = false : obj.statusChecked = false
          this.form = obj
        }
      })
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
    onChange(e) {
      let status = 0
      e === true ? status = 1 :
        e === false ? status = 0 : ''
      this.form.status = status
    },

    handleSubmit(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let apiUrl = ''
          console.log('this.id', this.id)
          if (this.id) {
            this.form.id = this.id
            apiUrl = paychannel.edit
          } else {
            apiUrl = paychannel.add
          }
          console.log('this.fomr', this.form)
          apiUrl(this.form).then(res => {
            if (res.success) {
              this.$message.success('操作成功')
              // console.log('this.$parent 111',this.$parent)
              // this.$parent.$children.close() // TODO 无法关闭
              this.$emit('closeDrawer') // 通知 父组件 关闭 弹窗
              this.$emit('updateList') // 通知 爷组件 更新列表数据

              this.onClearForm()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    onClearForm() {
      this.form = {
        code: '',
        feeRate: '',
        config: '', // 渠道配置的 json 字符串
        merchantId: '',
        statusChecked: false,
        remark: ''
      }
    },
    onClose() {
      this.$emit('closeDrawer') // 通知 父组件 关闭 弹窗
    }
  },
  filter: {
    checkedFilter(flag) {
      let str = ''
      switch (flag) {
        case 0:
          str = false
          break;
        case 1:
          str = true
          break;
        default:
          str = false
          break;
      }
      return str
    }
  }
}
</script>