<template>
  <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 12 }" >
    <a-form-model-item label="商户号" prop="no">
      <a-input v-model="form.no" />
    </a-form-model-item>
    <a-form-model-item label="商户名称" prop="name">
      <a-input v-model="form.name" />
    </a-form-model-item>
    <a-form-model-item label="商户简称" prop="shortName">
      <a-input v-model="form.shortName" />
    </a-form-model-item>
    <a-form-model-item label="开启状态" prop="statusChecked" >
      <a-switch @change='onChange' v-model="form.statusChecked" checked-children="开" un-checked-children="关" />
    </a-form-model-item>
    <a-form-model-item label="备注" prop="remark">
      <a-input type="textarea" v-model="form.remark" />
    </a-form-model-item>

    <div class='foot-btn'>
      <!-- <a-button :style="{ marginRight: '8px' }" @click='onClose'>
        取消
      </a-button> -->
      <a-button type='primary' @click="handleSubmit" >
        确定
      </a-button>
    </div>
  </a-form-model>
</template>
<script>
import * as merchants from '@/api/pay/merchants.js'
export default {
  props: {
    id: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      form: {
        no: '',
        name: '',
        shortName: '',
        status: 0,
        statusChecked: false,
        remark: ''
      },
      rules: {
        no: [
          { required: true, message: '请输入商户号' },
        ],
        name: [
          { required: true, message: '请输入商户名称' },
        ],
      }
    }
  },
  watch: {
    id(val) {
      if(val){
        this.getInfo(val)
      }else{
        this.onClearForm()
      }
    }
  },
  methods: {
    /**
     * 根据id获取详情
     */
    getInfo(id) {
      if(!id) return
      merchants.detail({id: id}).then(res => {
        if (res.success) {
          let obj = res.data
          obj.statusChecked = false
          obj.status === 1 ? obj.statusChecked = true :
          obj.status === 0 ? obj.statusChecked = false : obj.statusChecked = false
          this.form = obj
        }
      })
    },
    /**switch
     * 切换商户号状态 开启或者关闭
     */
     onChange(e) {
      let status = 0
      e === true ? status = 1 :
      e === false ? status = 0 : ''
      this.form.status = status
    },
    /**
     * 提交表单
     */
    handleSubmit(e) {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          let apiUrl = ''
          console.log('this.id',this.id)
          if(this.id) {
            this.form.id = this.id
            apiUrl = merchants.edit
          }else{
            apiUrl = merchants.add
          }

          apiUrl(this.form).then(res => {
            if (res.success) {
              this.$message.success('操作成功')
              // console.log('this.$parent 111',this.$parent)
              // this.$parent.$children.close() // TODO 无法关闭
              this.$emit('closeDrawer') // 通知 父组件 关闭 弹窗
              this.$emit('updateList') // 通知 爷组件 更新列表数据

              this.onClearForm()
            } else {
              this.$message.error(res.message)
            }
          })
        } else {
          return false;
        }
      });
    },
    onClearForm() {
      this.form = {
        id: '',
        no: '',
        name: '',
        shortName: '',
        status: 0,
        statusChecked: false,
        remark: ''
      }
    }
  },
  filter: {
    checkedFilter(flag) {
      let str = ''
      switch (flag) {
        case 0: str = false
          break;
        case 1: str = true
          break;
        default: str = false
          break;
      }
      return str
    }
  }
}
</script>